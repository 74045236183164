@import "../assets/colors.scss";
.services{
    padding: 3rem 0;
    .details{
        .intro{
            h1{
                font-weight: bolder;
                color: $secondary-color;
                padding-bottom: .5rem;
                text-transform: capitalize;
                line-height: 1.4;
            }
            p{
                color: $secondary-color;
                padding-bottom: 3rem;
            }
        }
        .description{
            display: flex;
            gap: 30px;
            .icon__holder{
                background: $primary-color;
                display: block;
                height: 30%;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: .8rem 1rem;
                border-radius: 6px;
            }
            .description__details{
                h4{
                    color: $secondary-color;
                    font-weight: bolder;
                }
                p{
                    color: $secondary-color;
                    line-height: 2;
                    opacity: .7;
                    padding-top: .3rem;
                }
            }
        }
        .description2{
            padding-top: 1rem;
        }
    }
}

//Media Queries
@media only screen and (max-width: 600px){
    .details{
        .intro{
            h1{
                font-size: 1.4rem !important;
                line-height: 1.6 !important;
            }
        }
    }
    .description{
        gap: 15px !important;
        flex-direction: column;
        .icon__holder{
            padding: .8rem .8rem !important;
            width: 15% !important;
        }
        .description__details{
            padding-top: .7rem;
        }
    }
    .service__img{
        margin-top: 2rem;
    }
}