@import "./components/assets/colors.scss";
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400&display=swap');
body{
    font-family: "Nunito Sans";
}
ul {
    list-style: none;
}

.container{
    max-width: 1300px;
    margin: 0 auto;
    padding: 0 1rem;
}