@import "../assets/colors.scss";

.partners{
    padding: 3rem 0;
    // overflow-x: hidden !important;
    h1{
        font-weight: bolder;
        color: $secondary-color;
        text-transform: uppercase;
    }
    p{
        color: $secondary-color;
        line-height: 2;
        opacity: .8;
    }
    .button__section{
        padding-top: 4rem;
        padding-bottom: 3rem;
        .partners__button{
            background: $primary-color;
            color: #FFF;
            padding: 1.2rem 1.5rem;
            border-radius: 6px;
            text-decoration: none;
        }
        :hover{
            background: $secondary-color;
            transition: all ease-in .5s;
        }
    }

    .partners__grid{
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        grid-gap: 30px;
        padding-top: 3rem;
        .partners__img{
            max-width: 300px;
            height: auto;
            border-radius: 6px;
        }
    }
}

//Media Queries
@media only screen and (max-width: 1000px){
    .partners__img{
        max-width: 100% !important;
    }
}

@media only screen and (max-width: 600px){
    .partners__grid{
        display: flex !important;
        overflow-x: auto;
        gap: 10px !important;
        min-width: 100%;
        .partners__img{
            min-width: 300px;
        }
    }
}