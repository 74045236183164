
@import "../assets/colors.scss";
.about{
    padding-top: 10rem;
    .heading{
        max-width: 650px;
        margin: 0 auto;
        text-align: center;
        padding-top: 4rem;
        padding-bottom: 2rem;
        h1{
            font-weight: bolder;
            color: $secondary-color;
        }
        p{
            padding-top: .5rem;
            line-height: 2;
            color: $secondary-color;
        }
    }

    .grid__wrapper{
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
        grid-gap: 30px;
        padding-bottom: 4rem;
        .grid__item1, .grid__item3{
            margin-top: 2rem !important;
        }
        .grid__item2{
            margin-bottom: 2rem;
        }
        .grid__item{
            text-align: center;
            background: #FAFAFA;
            padding: 2rem;
            border-radius: 6px;
            position: relative;
            .box{
                height: 50px;
                width: 50px;
                border-radius: 50px;
                position: absolute;
                bottom: -20px;
                background: $primary-color;
                display: flex;
                justify-content: center;
                align-items: center;
                left: 45%;
                font-weight: bold;
            }
            .img__holder{
                padding-bottom: 1rem;
                padding-top: 2rem;
                .icons{
                    max-width: 50px;
                    height: auto;
                }
            }
            .text{
                h5{
                    color: $secondary-color;
                    font-weight: bold;
                    padding-bottom: .5rem;
                }
                p{
                    color: $secondary-color;
                    line-height: 1.7;
                    opacity: .7;
                }
            }
        }
    }
}

//Media Queries
@media only screen and (max-width: 1000px){
    .about{
        padding-top: 10rem !important;
        .heading{
            padding-top: 4rem !important;
        }
        .grid__wrapper{
            grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)) !important;
            grid-gap: 40px;
            .grid__item{
                text-align: left;
            }
            .grid__item1, .grid__item3{
                margin-top: 0 !important;
            }
            .grid__item2{
                margin-bottom: 0 !important;
            }
        }
    }
}
