@import "../assets/colors.scss";

.mission{
    padding: 4rem 0;
    .heading{
        h1{
            font-weight: bolder;
            color: $secondary-color;
            text-align: center;
            text-transform: capitalize;
            padding-bottom: 3rem;
        }
    }
    .mission__content{
        padding-top: 5rem;
        display: flex;
        gap: 30px;
        .icon__holder{
            background: $primary-color;
            display: block;
            height: 30%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: .6rem .8rem;
            border-radius: 6px;
        }
        h3{
            font-weight: bold;
            color: $secondary-color;
            padding-bottom: .5rem;
        }
        p{
            color: $secondary-color;
            line-height: 2;
            opacity: .7;
        }
    }
}

//Media Queries
@media only screen and (max-width: 600px){
    h1{
        font-size: 1.7rem !important;
        line-height: 1.6 !important;
    }
    .mission__content{
        gap: 15px !important;
        flex-direction: column;
        .icon__holder{
            width: 20%
        }
    }
    .mission_img{
        max-width: 320px !important;
        height: auto;
    }
}