@import "../assets/colors.scss";
.floatingbar{
    position: absolute;
    top: 80vh;
    padding: 4rem 0;
    right: 0;
    left: 0;
    .content{
        background: $secondary-color;
        border-radius: 20px;
        padding: 3rem;
        color: #FFF;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        grid-gap: 30px;
        .item{
            display: flex;
            gap: 25px;
            padding-top: 1rem;
            .icon__holder{
                background: $primary-color;
                display: block;
                height: 32%;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: .5rem 1rem;
                border-radius: 6px;
            }
            .item__text{
                h5{
                    font-weight: bold;
                }
                p{
                    padding-top: .3rem;
                    font-size: .95rem;
                    opacity: .85;
                    line-height: 2;
                }
            }
        }
    }
}

//Media Queries
@media only screen and (max-width: 800px){
    .floatingbar{
        position: relative !important;
        top: -80px;
        height: 70vh !important;
        padding-bottom: 0 !important;
        margin-bottom: 6rem;
        
        padding: 0 .5rem !important;
        .content{
            padding-bottom: 3rem !important;
            .item{
                flex-direction: column;
                .icon__holder{
                    width: 20%;
                    padding: .8rem 1rem;
                }
            }
            .item__text3{
                padding-bottom: 2.5rem;
            }
        }
    }
}