@import "../../components/assets/colors.scss";

.partners__herosection{
    color: #FFF;
    padding: 10rem 0;
    height: 95vh;
    width: 100vw;
    position: relative;
    background: $primary-color;
    overflow-x: hidden !important;
    .hero__img{
        max-width: 450px;
        height: auto;
      }
    .partners__text{
        padding-top: 4rem;
        h1{
            font-weight: bolder;
            font-size: 3rem;
            font-family: "Nunito Sans" !important;
            line-height: 1.3;
            padding-bottom: 1rem;
        }
        p{
            font-size: 1rem;
            opacity: .85;
            line-height: 2.2;
            padding-bottom: 2rem;
        }
        .partners__btn{
            text-decoration: none;
            padding: 1.2rem 1.5rem;
            border-radius: 6px;
            background: #fff;
            color: $secondary-color;
        }
    }
}

.partners__list{
    background: #000;
    padding: 5rem 0rem;
    .partners__grid__section{
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
        grid-gap: 40px;
        padding-bottom: 5rem;
        .partners__grid__item{
            .partner__img{
                border-radius: 6px;
            }
        }
    }
}

@media only screen and (max-width: 800px){
    .partners__herosection{
        padding: 4rem 0 !important;
        .partners__text{
            h1{
                font-size: 2.5rem !important;
            }
        }
        .hero__img{
            margin-top: 5rem !important;
            max-width: 320px;
            height: auto;
        }
    }
    .partners__grid__section{
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)) !important;
        grid-gap: 20px !important;
    }
}

// .partners__herosection::before{
//     content: "";
//     height: 75vh;
//     width: 100vw;
//     position: absolute;
//     left: 50%;
//     z-index: -100;
//     border-radius: 50%;
//     // background: linear-gradient(rgba(9,25,99, .98), rgba(9,25,99, .98)), url("../../components/assets/images/waves.svg");
//     // background-position: right;
//     // background-size: 500px;
//     background: $primary-color;
//     transform-origin: bottom;
//     transform: translate(-50%) scale(3);
//     overflow-x: hidden !important;
// }