@import "../assets/colors.scss";

.team{
    background: $primary-color;
    color: #FFF;
    position: relative;
    height: auto;
    .content{
        max-width: 600px;
        margin: 0 auto;
        position: relative;
        bottom: -100px;
        h1{
            font-weight: bolder;
            line-height: 1.4;
            text-align: center;
        }
        .team__grid{
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
            grid-gap: 20px;
            padding-top: 3rem;
            padding-bottom: 3rem;
            .team__grid__item{
                .wrapper{
                display: flex;
                gap: 30px;
                justify-content: center;
                align-items: center;

                background: #FFF;
                box-shadow: 6px -8px 50px rgba(112, 112, 112, 0.25);
                border-radius: 6px;
                color: $primary-color;
                padding: 0 2rem;
                .team__img{
                    max-width: 100px;
                    // height: auto;
                    // padding-bottom: 1rem;
                    // padding-top: 1rem;
                }
                .position{
                    // padding: 1rem 0;
                    font-weight: 400;
                    line-height: 1.5;
                    color: $secondary-color;
                    opacity: .8;
                    padding-top: .3rem;
                    padding-bottom: 1rem;
                }
                .name{
                    font-weight: bold;
                    font-size: 1rem;
                    color: $secondary-color;
                    padding-top: 3rem;
                }
                .socials{
                    justify-content: space-between;
                    width: 20%;
                    margin: 0 0;
                    padding-bottom: 2rem;
                    .linkedin, .twitter, .facebook{
                        color: $secondary-color;
                        font-weight: bolder;
                        font-size: 1.5rem;
                    }
                    .linkedin, .twitter:hover{
                        color: $secondary-color !important;
                    }
                }
                }
            }
        }
    }
}

//Meida Queries
@media only screen and (max-width: 600px){
    .wrapper{
        flex-direction: column;
        padding: 2rem 2rem !important;
        justify-content: start !important;
        align-items: flex-start !important;
        .name{
            padding-top: 0 !important;
        }
        .socials{
            width: 50% !important;
        }
    }
}