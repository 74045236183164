@import "../assets/colors.scss";
.media{
    padding-top: 10rem;
    padding-bottom: 3rem;
    .media__content{
        max-width: 700px;
        margin: 0 auto;
        text-align: center;
        .intro{
            padding-bottom: 4rem;
            h4{
                color: $secondary-color;
                padding-bottom: .4rem;
                font-size: 1.2rem;
            }
            h1{
                font-weight: bolder;
                color: $secondary-color;
                line-height: 1.3;
            }
        }
        .media__img{
            max-width: 600px;
            height: auto;
        }
    }
}

//Media Queries
@media only screen and (max-width: 600px){
    .media__img{
        max-width: 320px !important;
        height: auto;
    }
}