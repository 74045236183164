@import "../assets/colors.scss";

.testimonials{
    padding: 6rem 0;
    background: #ECF8FF;
    position: relative;
    .test__img__holder{
        .client{
          z-index: 10;
          position: absolute;
        }
        .client__img{
            max-width: 65px;
            height: auto;
        }
        .client1{
          left: 15%;
          top: 20%;
        }
         .client2{
          right: 50%;
          top: 40%;
          animation-delay: 4s;
        }
         .client3{
          right: 15%;
          top: 20%;
        }
         .client4{
          right: 15%;
          bottom: 20%;
        }
        .client5{
            left: 15%;
            bottom: 20%;
          }
      }
    h1{
        font-weight: bolder;
        color: $secondary-color;
    }
    .testimony{
        text-align: center;
        padding-top: 2rem;
        p{
            color: $secondary-color;
            line-height: 2;
            opacity: .8;
        }
        strong{
            color: $secondary-color;
        }

        //Testimony Item
        .item{
          display: flex;
          flex-direction: column;
          text-align: center;
          .testifier__img{
            border-radius: 50px;
            max-width: 100px;
            height: auto;
            margin: 0 auto;
          }
          h5{
            color: $secondary-color;
          }
          p{
            padding-top: 1rem;
            font-size: 1.2rem !important;
          }
        }
    }
}