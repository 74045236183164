@import "../assets/colors.scss";

.navbar__wrapper{
    background: $primary-color;
    position: fixed;
    width: 100%;
    z-index: 100;
    .navbar__content{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem 0;
        //logo
            .logo{
                max-width: 60px;
            }

        //Menu ul section
        ul{
            display: flex;
            justify-content: space-between;
            align-items: center;
            li{
                padding-top: 1rem;
                padding-left: 2rem;
                padding-right: 2rem;
                a, .ordinary{
                    text-decoration: none;
                    color: #fff;
                    font-size: 1rem;
                }
                //Register button
            }
        }
        .button{
            display: none;
        }
    }
}

//Media Queries
@media only screen and (max-width: 1000px){
    .navbar__wrapper{
        position: relative;
        // padding: 1rem 0;
            ul{
                z-index: 10000;
                background: $primary-color;
                flex-direction: column;
                padding: 2rem 0;
                position: absolute;
                left: -1000px;
                width: 100%;
                top: 100%;
                justify-content: center !important;
                align-items: center !important;
                height: 100vh;
                li{
                    padding: 1.5rem 0;
                }
            }
        .button{
            display: block !important;
        }
    }
}
//Show Navigation
.showNav{
    left: 0 !important;
    transition: all ease-in-out !important;
}

@media only screen and (max-width: 600px){
    .logo{
        max-width: 50px !important;
        height: auto;
    }
}