@import "../assets/colors.scss";

footer{
   display: flex;
   justify-content: center;
   align-items: center;
   background: $secondary-color;
   padding: 6rem 0;
   span{
       background: $primary-color;
       border-radius: 10px;
       padding: 1rem 5rem;
       a{
        color: #FFF;
        text-decoration: none;
        font-size: .9rem;
       }
   }
   p{
       color: #FFF;
       text-align: center;
       padding-top: 4rem;
       opacity: .8;
   }
}
a:hover{
    color: #fff !important;
}