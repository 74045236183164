@import "../assets/colors.scss";
.contact{
    // background: $primary-color;
    // background: linear-gradient(rgba(9,25,99, .985), rgba(9,25,99, .985)), url("../assets/images/waves.svg");
    // background-position: center;
    background: $primary-color;
    padding: 6rem 0;
    position: relative;
    .balls__holder{
        .ball{
          height: 20px;
          width: 20px;
          z-index: 1000;
          border-radius: 50px;
          position: absolute;
          animation-name: moveBalls, rotateBalls;
          animation-duration: 5s;
          animation-iteration-count: infinite;
          animation-direction: alternate;
        }
        .ball1{
          background: #EB4D70;
          left: 2%;
          bottom: 40%;
        }
         .ball2{
          background: #7B7FDA;
          right: 60%;
          top: 30%;
          animation-delay: 4s;
        }
         .ball3{
          background: #EB7051;
          right: 50%;
          top: 60%;
        }
         .ball4{
          background: #FF9A68;
          right: 5%;
          top: 20%;
          animation-delay: 7s;
        }
      }
    .contact__text{
        color: #fff;
        padding-top: 3rem;
        h2{
            font-weight: bolder;
            padding-bottom: 1rem;
            text-transform: capitalize;
            font-size: 2.2rem;
            line-height: 1.5;
        }
        p{
            line-height: 2;
            opacity: .8;
        }
    }

    .form__wrapper{
        background: #fff;
        padding: 3rem 3rem;
        border-radius: 6px;
        form{
            input{
                height: 3.5rem;
                padding: 0 1rem;
                box-shadow: none;
                border: 1px solid #c4c4c4;
            }
            textarea{
                padding: 1rem 1rem;
                box-shadow: none;
                border: 1px solid #c4c4c4;
            }
            .form__btn{
                background: $primary-color;
                border: none;
                color: #fff;
                padding: 1rem 2rem;
                border-radius: 6px;
            }
            .form__btn:hover{
                background: $secondary-color !important;
                transition: all ease-in .5s;
            }
        }
    }
}

//media Queries
@media only screen and (max-width: 600px){
    .contact__text{
        padding-top: 0 !important;
        h2{
            font-size: 1.5rem !important;
        }
        p{
            padding-bottom: 2rem;
        }
    }
    .form__wrapper{
        padding: 2rem 1.5rem !important;
    }
}