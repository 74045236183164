@import "../assets/colors.scss";
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@800&display=swap');
.herosection{
    // background: $primary-color;
    overflow-x: hidden !important;
    color: #fff;
    position: relative;
    height: 100vh;
    // padding: 5rem 0;
    .blob__section{
      .pattern__img{
        position: absolute;
        max-width: 500px;
        height: auto;
        left: 10%;
        top: 21%;
        z-index: -10
      }
    }
    .hero__contents{
        padding: 8rem 0;
        position: relative;
        .hero__img{
          max-width: 500px;
          height: auto;
          padding-top: 5rem;
        }
        .text__section{
          padding-top: 5rem;
            h1{
                font-weight: bolder;
                font-size: 3rem;
                font-family: "Nunito Sans" !important;
                line-height: 1.3;
                padding-bottom: 1rem;
            }
            p{
                font-size: 1rem;
                opacity: .85;
                line-height: 2.2;
            }
            .button__holder{
                gap: 20px;
                padding-top: 1.4rem;
                .button{
                    text-decoration: none;
                    padding: 1rem 2rem;
                    border-radius: 6px;
                    border: none;
                    cursor: pointer;
                }
                .primary{
                    background: $secondary-color;
                    color: #FFF;
                }
                .primary:hover{
                    background: #fff;
                    color: $primary-color;
                    transition: all ease-in .5s;
                }
                .secondary{
                   background: #FFF;
                   color: $secondary-color;
                }
                .secondary:hover{
                    background: $secondary-color !important;
                    transition: all ease-in .5s;
                    color: #fff;
                }
            }
        }
    }
}
.herosection::before{
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 100vw;
  height: 100vh;
  z-index: -100;
  background: $primary-color;
  // background: linear-gradient(rgba(232,71,101, .95), rgba(232,71,101, .95));
  border-radius: 50%;
  transform-origin: bottom;
  transform: translate(-50%) scale(4);
}

//Media Queries
@media only screen and (max-width: 1000px){
  .herosection{
    padding: 2rem 0 !important;
  }
  .hero__contents{
    padding: 4rem 0 !important;
  }
  .text__section{
    h1{
      font-size: 2.2rem !important;
    }
  }
    .button__holder{
        // flex-direction: column;
        padding-bottom: 2rem;
        .button{
            text-align: center;
        }
    }
    .hero__img{
        margin-top: 2rem;
    }
}

  @media only screen and (max-width: 600px){
    .herosection{
      padding: 0 !important;
    }
    .herosection::before{
      border-radius: 50% !important;
      .button__holder{
        padding-bottom: 4rem;
      }
    }
    .text__section{
      padding-top: 2rem !important;
    }
    .herosection__form{
      right: 0 !important;
      width: 100% !important;
      margin: 0 auto !important;
      left: 0 !important;
    }
  }

 //Herosection Form 
 .herosection__form{
   position: absolute;
   max-width: 600px;
   margin: 0 auto;
   background: #FFF;
   box-shadow: 6px -8px 50px rgba(112, 112, 112, 0.25);
   right: 30% !important;
   width: 100%;
   padding: 2rem 3rem;
   border-radius: 6px;
   z-index: 1000;
   h3{
    color: $secondary-color;
    font-weight: bolder;
    text-align: center;
  }
  hr{
    background: $secondary-color !important;
  }
   .contact_form{
    input{
        height: 3.3rem;
        padding: 0 1rem;
        box-shadow: none;
        border: 1px solid #c4c4c4;
    }
    textarea{
        padding: 1rem 1rem;
        box-shadow: none;
        border: 1px solid #c4c4c4;
    }
    .hero__form__btn{
      text-decoration: none;
      padding: 1rem 2rem;
      border-radius: 6px;
      background: $primary-color;
      border: none;
      color: #FFF;
      cursor: pointer;
    }
    .hero__form__btn:hover{
        background: $secondary-color !important;
        transition: all ease-in .5s;
    }
}
 }
